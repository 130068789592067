<template>
  <div class="c-card">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: 'SeCard',
};
</script>
